import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2019/02/27113245/escalation-promo-300x195.png",
        "alt": null
      }}></img></p>
    <h1><strong parentName="h1">{`Track key goals, like escalation, purchases and more!`}</strong></h1>
    <p>{`Goals are available to `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/pricing"
      }}>{`Dashbot Subscribers!`}</a><br parentName="p"></br>{`
`}{`Subscribe now and configure your goals report to better understand how your users are completing crucial actions in your application.`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/pricing"
      }}>{`Subscribe Now`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      